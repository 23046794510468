<template lang="pug">
  .privacy
    Breadcrumb(:current_path="{name: 'Privacy', title: 'Privacy'}")

    .container.privacy__container
      h1.title_2.privacy__title(v-html="$t('pages.privacy.title')")

      .privacy__content
        p.privacy__text(v-html="$t('pages.privacy.text')")

        ul.privacy__list
          li.privacy__item(
            v-for="(item, index) in $t('pages.privacy.list')"
            :key="index"
          )
            ExpandableItem.privacy__expandable
              template(v-slot:toggle)
                h3.privacy__expandable-title.expandable__title
                  span(v-html="item.title")
                  BaseIcon(name="chevron" class="expandable__chevron")
              template(v-slot:expandable)
                p.privacy__item-text(v-html="item.text")
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import ExpandableItem from '@/components/common/ExpandableItem'
import BaseIcon from '@/components/common/BaseIcon'

export default {
  name: 'Privacy',
  components: { Breadcrumb, ExpandableItem, BaseIcon }
}
</script>
